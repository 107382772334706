import styled from "styled-components";
import FundoCompletoOriginal from "../assets/images/fundo-complet-original.png";
import DoubleOrangeAndBlueBlur from "../assets/images/double-orange-blue-blur.png";

export const Fundo = styled.div`
  background-image: url(${FundoCompletoOriginal});
  background-position: center;
  background-size: cover;
  mix-blend-mode: color;
`;

export const Button = styled.button`
  width: 437px !important;
  height: 54px !important;
  border: 0 !important;
  background-image: linear-gradient(to right, #FF2E00, #FF772B);
  border-radius: 9px;
  font-size: 20px;
  font-weight: bold;
  color: #FFF;

  :hover{
    cursor: pointer;
    background-color: blue;
  }

  @media (max-width: 600px) {
    width: 98% !important;
    font-size: 18px;
    height: 50px !important;
  }
`;

export const DivCamadaBranca = styled.div`
  background-color: #fff;
  opacity: 0.03;

  position: fixed;
  top: 0;

  mix-blend-mode: screen;

  width: 100%;
  height: 100%;
`;

export const OrangeAndBlueBlur = styled.div`
  background-image: url(${DoubleOrangeAndBlueBlur});

  position: fixed;
  top: 0;

  width: 100%;
  height: 100%;

  mix-blend-mode: color;
`;

export const BolaLaranja = styled.div`
  width: 500px;
  height: 500px;
  border-bottom-right-radius: 50%;

  top: -200px;
  left: -200px;
  position: absolute;

  background-color: #FF3700;
  mix-blend-mode: screen;
  filter: blur(500px);
`;
export const BolaAzul = styled.div`
  width: 400px;
  height: 400px;
  border-bottom-left-radius: 50%;

  top: -150px;
  right: 0px;
  position: absolute;

  background-color: #0040FF;
  mix-blend-mode: screen;
  filter: blur(500px);
`;

export const ImgLogosHTMLeCSS = styled.img`
  margin-top: 50px;
  margin-bottom: 58px;
  margin-left: 50px;
  width: 292px;
  height: auto;

  @media (max-width: 600px) {
    margin-left: 25px;
    width: 150px;
    height: auto;
    margin-top: 20px;
    margin-bottom: 40px;
  }
`;

export const Container = styled.div`
  width: 100%;
  height: 100%;

  display: flex;
  flex-direction: column;
  align-items: center;

  text-align: center;
  font-family: "Poppins", sans-serif;

  position: relative;

  @media (max-width: 1200px) {
    padding: 0 10px !important;
  }

  >h1{
    font-size: 52px;
    line-height: 126%;
    color: #FFF;
    font-weight: bold;

    margin-bottom: 25px !important;

    .html5{
      color: #FF5C00;
    }
    .css3{
      color: #006DEE;
    }
    .ponto{
      color: #00A3FF;
    }

    @media (max-width: 600px) {
      font-size: 33px;
    }
  }
  >h2{
    font-size: 27px;
    line-height: 126%;
    color: #D9D9D9;

    margin-bottom: 100px !important;

    @media (max-width: 600px) {
      font-size: 21px;
      margin-bottom: 90px !important;
    }
  }

  >iframe{
    width: 100%;
    max-width: 900px;
    height: 585px;

    @media (max-width: 600px) {
      height: 300px !important;
    }
  }

  >form{
    display: flex;
    flex-direction: column;
    align-items: center;
    gap: 18px;

    margin-top: 100px !important;

    @media (max-width: 600px) {
      margin-top: 90px !important;
    }

    h3{
      font-size: 36px;
      font-weight: bold;
      color: #fff;
      margin-top: 20px !important;

      @media (max-width: 600px) {
        font-size: 25px;
      }
    }
    p{
      font-size: 27px;
      line-height: 126%;
      color: #FFF;
      margin-bottom: 43px !important;

      @media (max-width: 600px) {
        font-size: 18px;
        margin-bottom: 25px !important;
      }
    }
    input{
      width: 437px !important;
      height: 54px !important;

      border: 0 !important;
      outline: none;
      border-radius: 9px !important;
      background-color: #D9D9D9;
      color: #111;
      font-size: 20px;
      padding: 15px 20px !important;

      ::placeholder{
        color: rgb(121, 121, 121, 0.9);
      }

      @media (max-width: 600px) {
        width: 98% !important;
        font-size: 18px;
        height: 50px !important;
      }
    }
    button{
      width: 437px !important;
      height: 54px !important;
      border: 0 !important;
      background-image: linear-gradient(to right, #FF2E00, #FF772B);
      border-radius: 9px;
      font-size: 20px;
      font-weight: bold;
      color: #FFF;

      :hover{
        cursor: pointer;
        background-color: blue;
      }

      @media (max-width: 600px) {
        width: 98% !important;
        font-size: 18px;
        height: 50px !important;
      }
    }
  }
`;

export const LogoCabeca = styled.img`
  position: absolute;
  top: 90%;
  width: 52px;
`;

export const Footer = styled.footer`
  width: 100%;
  height: 330px !important;

  background: linear-gradient(to bottom, #2E2E2E00, #2E2E2EFF);
  mix-blend-mode: overlay;

  @media (max-width: 600px) {
    height: 300px !important;
  }
`;
