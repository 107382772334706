import LogosHTML5eCSS3Img from "../assets/images/logos-html-css.png";
import LogoCabecaImg from "../assets/images/logo-cabeca.svg";
import {
  Fundo,
  DivCamadaBranca,
  OrangeAndBlueBlur,
  BolaLaranja,
  BolaAzul,
  Container,
  ImgLogosHTMLeCSS,
  Footer,
  LogoCabeca,
  Button
} from "./styles";

export default function App() {
  return (
    <Fundo>
      <DivCamadaBranca />
      <OrangeAndBlueBlur />
      <BolaLaranja />
      <BolaAzul />
      <Container>
        <ImgLogosHTMLeCSS src={LogosHTML5eCSS3Img} alt="Logo da HTML5 e CSS3" />
        <h1>
          Aulão gratuito de <span className="html5">HTML5</span> e <span className="css3">CSS3</span><span className="ponto">.</span>
        </h1>
        <h2>
          Assista abaixo ao passo a passo gratuito feito<br/>
          por um programador sênior para você se tornar<br/>
          um programador de sucesso em 2025.
        </h2>

        <iframe
          id="panda-d3906e6a-ac60-45dc-b126-df9a9e10a18f" 
          src="https://player-vz-352a1526-b3d.tv.pandavideo.com.br/embed/?v=1287e2fb-97de-4259-9445-31f43f118000&iosFakeFullscreen=true"
          allow="accelerometer;gyroscope;autoplay;encrypted-media;picture-in-picture"
          allowFullScreen
        ></iframe>

        <form>
          <h3>Deseja ver mais aulas como essa?</h3>
          <p>
            Nossa escola tem mais de 450 aulas e 2 lives <br/>por semana
            esperando por você!
          </p>
        </form>

        <center>
          <a href="https://onovoprogramador.com" target="_blank">
            <Button>QUERO SER UM PROGRAMADOR</Button>
          </a>
        </center>

        <br/><br/>

        <LogoCabeca alt="logo-onovoprogramador" src={LogoCabecaImg} style={{mixBlendMode: "normal"}} />

        <Footer />
      </Container>
    </Fundo>
  );
}
